import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { Button } from '../../atoms/Button'
import { setCookie } from 'nookies'
import Link from 'next/link'
import { getHref } from '../../../helpers/getHref'
import { getText } from '../../../helpers/getText'
import { setThirdPartyConsent } from '../../../helpers/setThirdPartyConsent'

export const CookieModal = ({ isOpen, closeModal, blok, data, preview }) => {
  const [showSettings, setShowSettings] = useState(false)
  const [marketingChecked, setMarketingChecked] = useState(false)
  const [analyticsCheck, setAnalyticsCheck] = useState(false)
  const [infoText, setInfoText] = useState(blok?.cb_necessary_info_text)
  const setConsent = ({ categories }) => {
    setCookie(null, 'cookieConsentCategories', categories, {
      maxAge: 365 * 24 * 60 * 60, // one year
      path: '/',
    })

    const customEvent = new CustomEvent('cookies-modal-close-event')
    window.dispatchEvent(customEvent)

    setThirdPartyConsent({ consent: categories, preview })
    closeModal()
  }

  if (blok?.show_cookie_modal === 'no') {
    return null
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        style={{ zIndex: 999 }}
        as="div"
        className="fixed inset-0 overflow-y-auto"
        onClose={() => {}}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div className="min-h-screen px-3 text-center">
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl">
              <Dialog.Title
                as="p"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {blok?.cb_title
                  ? getText(blok?.cb_title, data)
                  : 'We use cookies'}
              </Dialog.Title>
              {showSettings ? (
                <div
                  className={
                    'flex flex-col  lg:flex-row space-y-6 lg:space-x-6 text-sm text-gray-500 mt-6'
                  }
                >
                  <div className={'flex flex-col space-y-2'}>
                    <div className={'flex space-x-2 items-center'}>
                      <input
                        className={'pr-2'}
                        name="necessary"
                        type="checkbox"
                        checked={true}
                        onChange={() => {}}
                      />
                      <p
                        onClick={() => {
                          setInfoText(
                            getText(blok?.cb_necessary_info_text, data),
                          )
                        }}
                        className={'underline cursor-pointer'}
                      >
                        {getText(blok?.cb_necessary_checkbox_title, data)}
                      </p>
                    </div>
                    <div className={'flex space-x-2 items-center'}>
                      <input
                        name="analytics"
                        type="checkbox"
                        checked={analyticsCheck}
                        onChange={() => {
                          setAnalyticsCheck(!analyticsCheck)
                        }}
                      />
                      <p
                        onClick={() => {
                          setInfoText(
                            getText(blok?.cb_analytics_info_text, data),
                          )
                        }}
                        className={'underline cursor-pointer'}
                      >
                        {getText(blok?.cb_analytics_checkbox_title, data)}
                      </p>
                    </div>
                    <div className={'flex space-x-2 items-center'}>
                      <input
                        name="marketing"
                        type="checkbox"
                        checked={marketingChecked}
                        onChange={() => {
                          setMarketingChecked(!marketingChecked)
                        }}
                      />
                      <p
                        onClick={() => {
                          setInfoText(
                            getText(blok?.cb_marketing_info_text, data),
                          )
                        }}
                        className={'underline cursor-pointer'}
                      >
                        {getText(blok?.cb_marketing_checkbox_title, data)}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className={'text-gray-500'}>{infoText}</p>
                  </div>
                </div>
              ) : (
                <div className="mt-3 text-sm text-gray-500">
                  <p className="text-sm text-gray-500 mb-3">
                    {getText(blok?.cb_default_info_text, data)}
                  </p>

                  {blok?.cb_privacy_url?.url && (
                    <Link href={getHref(blok?.cb_privacy_url)}>
                      <a className={'underline cursor-pointer'}>
                        {getText(blok?.cb_privacy_url_title, data)}
                      </a>
                    </Link>
                  )}
                </div>
              )}

              <div className="mt-4 flex flex-col lg:flex-row space-y-3 lg:space-x-6 lg:space-y-0">
                <div className="flex flex-1 justify-center lg:justify-start">
                  <Button
                    className={'w-full lg:w-auto'}
                    onClick={() => {
                      setShowSettings(!showSettings)
                    }}
                  >
                    {showSettings
                      ? getText(blok?.cb_hide_settings_button_title, data)
                      : getText(blok?.cb_show_settings_button_title, data)}
                  </Button>
                </div>

                {(showSettings || blok?.cb_reject_button != 'hide') && (
                  <Button
                    onClick={() => {
                      if (showSettings) {
                        let categories = 'necessary'
                        if (analyticsCheck) {
                          categories += ',analytics'
                        }
                        if (marketingChecked) {
                          categories += ',marketing'
                        }
                        if (analyticsCheck && marketingChecked) {
                          categories = 'necessary,analytics,marketing,all'
                        }
                        setConsent({
                          categories: categories,
                        })
                      } else {
                        setConsent({
                          categories: 'necessary',
                        })
                      }
                      closeModal()
                    }}
                  >
                    {showSettings
                      ? getText(blok?.cb_save_button_title, data)
                      : getText(blok?.cb_reject_button_title, data)}
                  </Button>
                )}

                <Button
                  id={'v95-cookie-accept-button'}
                  onClick={() => {
                    setConsent({
                      categories: 'necessary,analytics,marketing,all',
                    })
                    closeModal()
                  }}
                >
                  {getText(blok?.cb_accept_button_title, data)}
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
